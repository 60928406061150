
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import {
  debounceTime,
  distinctUntilChanged,
  map,
} from "rxjs/operators";
import { Subject } from "rxjs";
import Vue from "vue";
import UrlUtil from '@/utils/UrlUtil'

export default {

  components: {
    Multiselect
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) );

    return {
      page: 0,
      pageCount: 0,
      itemsPerPage: 10,
      productsSnippet: snippets ?? {},
      productsList: [],
      productsPaginatedList: {},
      searchedProductsPaginatedList: {},
      searchedProductsList: [],
      paginatedProductsList: [],
      paginatedSearchedProductsList: [],
      spacesList: [],
      organizationsList: [],
      organizationsNamesList: [],
      loadedPages: [],
      searchedData: [],
      searchedDataOriginal: '',
      selectedOrganization: '',
      currentManagerOrganization: '',
      searchKey: '',
      searchField: '',
      dialog: false,
      dialogDelete: false,
      dialogUpgrade: false,
      submitted: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        active: '',
        name: '',
        description: '',
        type: '',
        photoIds: '',
        availableSpaces: [],
        createDate: '',
      },
      defaultItem: {
        id: '',
        active: '',
        name: '',
        description: '',
        type: '',
        photoIds: '',
        availableSpaces: [],
        createDate: '',
      },
      sortBy: 'id',
      editedPrefix: '',
      searchResults: '',
      search$: new Subject(),
      sortDesc: true,
      errorKey: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldProduct: {},
      addressesDataList: [],
      itemsPerPageOptions: [ 0 ],
      productData: { products: [], count: Number },
      spaceData: [],
      totalPassengers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      canOverwrite: true,
    }
  },

  computed: {

    formTitle() {
      return this.editedIndex === -1 ? this.productsSnippet.add_product : this.productsSnippet.edit_product
    },

    headers() {
      return [
        { text: this.productsSnippet.id, value: 'id', width: '10%', sortable: true },
        { text: this.productsSnippet.name, value: 'name', width: '15%', sortable: true },
        { text: this.productsSnippet.type, value: 'type', width: '15%', sortable: true },
        { text: this.productsSnippet.brand, value: 'brand', width: '15%', sortable: true },
        { text: this.productsSnippet.actions, value: 'actions', width: '10%', sortable: false },
      ]
    },
  },

  watch: {

    dialog( val ) {
      val || this.close()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

    async searchKey( searchText ) {
      if ( searchText ) {
        this.search$.next( searchText );
        this.searchField = searchText
      } else {
        this.search$.next( '' );
        this.searchField = ''
        this.searchedData = [];
      }
    },

  },
  beforeRouteLeave( to, from, next ) {
    this.saveProductsDataToSessionStorage();
    // StorageService.deleteProductFromStorage();
    next()
  },

  async mounted() {
    this.searchAction();

    window.addEventListener( 'beforeunload', this.saveProductsDataToSessionStorage );
    let storageProducts = this.getProductsDataFromSessionStorage();
    if ( storageProducts?.data ) {
      this.productData = storageProducts;
      this.loadedPages.push( +storageProducts.currentPage );
      await this.getProductsPaginated();
    } else {
      await this.getProductsApi();
    }

    this.loading = false;

  },

  methods: {
    customSort( items ) {
      return items
    },

    async sortTable() {
      await Promise.resolve();
      this.changePage(this.currentPage ?? 1);
    },

    getSortQuery(comesFirst = false) {
      if (this.sortBy) {
        return `${comesFirst ? '?' : '&'}sortBy=${this.sortBy}&direction=${this.sortDesc ? 'DESC' : 'ASC'}`;
      }

      return '';
    },

    saveProductsDataToSessionStorage() {
      //TODO: remove
      return
      if ( this.canOverwrite ) {
        sessionStorage.setItem( 'products_paginated_list', JSON.stringify( this.productData ) )
        sessionStorage.setItem( 'products_searched_text', JSON.stringify( this.searchField ) )
        sessionStorage.setItem( 'products_searched_list', JSON.stringify( this.searchedDataOriginal ) )
        sessionStorage.setItem( 'products_paginated_list_save_date', Date.now().toString() )
      }
    },

    getProductsDataFromSessionStorage() {
      let productsData = JSON.parse( sessionStorage.getItem( 'products_paginated_list' ) );
      this.searchKey = JSON.parse( sessionStorage.getItem( 'products_searched_text' ) );
      if ( this.searchKey ) {
        productsData = {};
      }
      this.currentPage = productsData?.currentPage ? +productsData.currentPage : 1;
      return productsData;
    },


    openDetails( product ) {
      if ( product && product.id ) {
        this.$router.push( `product-details/${ product.id }` )
      }
    },

    searchAction() {
      this.search$ = new Subject();

      this.search$.pipe(
        map( text => text?.toString()?.toLowerCase() ),
        debounceTime( 1000 ),
        distinctUntilChanged()
      ).subscribe( async ( term ) => {
        this.currentPage = 1;

        if ( this.searchField === '' ) {
          await this.getProductsPaginated();
          return
        }

        let data = await this.searchText( term );
        await this.getSearchedProductsPaginated( data );
      } )
    },

    searchText( term ) {
      return ApiRequestService.getRequest( `api/admin-products?searchText=${ term }${this.getSortQuery()}` );
    },

    async changePage( page ) {
      this.productsPaginatedList = {};
      this.searchedProductsPaginatedList = {};
      this.paginatedSearchedProductsList.length = 0;
      this.paginatedProductsList.length = 0;
      this.productsList.length = 0;
      this.currentPage = page;

      if ( this.searchField ) {
        this.searchedDataOriginal = await ApiRequestService.getRequest( `api/admin-products?page=${ page }&searchText=${ this.searchField }${this.getSortQuery()}` );


        if ( this.searchedDataOriginal?.data?.length ) {
          await this.setSearchedProductsToProductsList( this.searchedDataOriginal.data );
        }
        return;
      }

      this.productData = await ApiRequestService.getRequest( `api/admin-products?page=${ page }${this.getSortQuery()}` );
      this.loadedPages = [this.currentPage];

      if ( this.productData?.data?.length ) {
        await this.setProductsToProductsList( this.productData.data );
      }
    },

    async getProductsApi() {
      this.productData = await ApiRequestService.getRequest( `api/admin-products${this.getSortQuery(true)}` );
      await this.getProductsPaginated();
    },

    async getProductsPaginated() {
      let productsCount = this.productData.totalElements;
      this.pagesCount = Math.ceil( this.productData.totalElements / 10 )
      if ( productsCount ) {
        await this.setProductsToProductsList( this.productData.data );
      }
    },

    async getSearchedProductsPaginated( data ) {
      this.pagesCount = Math.ceil( data.totalElements / 10 )

      this.searchedProductsList = [];
      this.paginatedSearchedProductsList.length = 0;
      this.searchedProductsPaginatedList = {};
      await this.setSearchedProductsToProductsList( data.data );

      this.searchedDataOriginal = data;
      this.searchedData = this.getObjClone( this.searchedProductsList )
    },

    async setProductsToProductsList( productData ) {
      productData.forEach( ( product, index ) => {
        let existsIndex = this.productsList.findIndex( item => item.id === product?.id );

        let productData = {
          id: product?.id,
          name: product.name,
          description: product.description,
          url: product.url,
          brand: product.brand,
          type: product?.type?.name,
          isActive: product.isActive,
          createDate: this.getToLocaleDate( product?.createDate ),
        };

        if ( existsIndex !== -1 ) {
          Vue.set( this.productsList, existsIndex, productData )

          let values = this.productsPaginatedList[this.currentPage] ? this.productsPaginatedList[this.currentPage].values() : [];
          let productsPaginatedListClone = [ ...values ];
          productsPaginatedListClone.forEach( item => {
            if ( item?.id === productData?.id ) {
              this.productsPaginatedList[this.currentPage].delete( item )
              this.productsPaginatedList[this.currentPage].add( productData )
            }
          } );
          return;
        }

        Vue.set( this.productsList, this.productsList.length, productData )

        if ( !this.productsPaginatedList[this.currentPage] ) {
          this.productsPaginatedList[this.currentPage] = new Set();
        }
        if ( this.productsPaginatedList[this.currentPage].size < 10 ) {
          this.productsPaginatedList[this.currentPage].add( productData );
        }
      } )

      if ( this.productsPaginatedList[this.currentPage] ) {
        this.paginatedProductsList = this.getObjClone( [ ...this.productsPaginatedList[this.currentPage] ] )
      }
    },

    async setSearchedProductsToProductsList( data ) {
      data.forEach( ( product, index ) => {
        let existsIndex = this.paginatedSearchedProductsList.findIndex( item => item.id === product?.id );
        let productData = {
          brand: product.brand,
          type: product?.type?.name,
          id: product?.id,
          active: product?.active,
          name: product?.name,
          createDate: this.getToLocaleDate( product?.createDate ),
        };

        if ( existsIndex !== -1 ) {
          let searchedProductsPaginatedListClone = this.getObjClone( this.searchedProductsPaginatedList[this.currentPage] )

          searchedProductsPaginatedListClone.forEach( ( item, index ) => {
            if ( item?.id === productData?.id ) {
              this.searchedProductsPaginatedList[this.currentPage][index] = productData
            }
          } );
          return;
        }

        if ( !this.searchedProductsPaginatedList[this.currentPage] ) {
          this.searchedProductsPaginatedList[this.currentPage] = [];
        }
        if ( this.searchedProductsPaginatedList[this.currentPage].length < 10 ) {
          this.searchedProductsPaginatedList[this.currentPage].push( productData );
        }
      } )

      if ( this.searchedProductsPaginatedList[this.currentPage] ) {
        this.paginatedSearchedProductsList = this.getObjClone( this.searchedProductsPaginatedList[this.currentPage] )
      }
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    addProductToProductList( data ) {
      let newProductData = {
        id: data?.id,
        active: data?.active,
        name: data?.name,
        createDate: this.getToLocaleDate( data?.createDate ),
      };

      if ( this.searchField ) {
        let existsIndex = this.paginatedSearchedProductsList?.findIndex( product => product.id === newProductData.id );
        if ( existsIndex !== -1 ) {
          this.paginatedSearchedProductsList[existsIndex] = newProductData;
        } else {
          this.paginatedSearchedProductsList.push( newProductData );
          this.searchedDataOriginal.data.totalElements++;
        }

        this.setSearchedProductsToProductsList( this.paginatedSearchedProductsList )

      } else {
        let existsIndex = this.productData?.data?.findIndex( product => product.id === newProductData.id );
        if ( existsIndex !== -1 ) {
          this.productData.data[existsIndex] = newProductData;
        } else {
          this.productData.data.push( newProductData );
          this.productData.totalElements++;
        }

        this.setProductsToProductsList( this.productData.data )
      }
    },

    getChangedData( oldProduct ) {
      const dataFormUpdate = {}
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldProduct[key] === this.editedItem[key] ) {
          continue
        }


        dataFormUpdate[key] = this.editedItem[key]
      }
      return dataFormUpdate
    },

    urlIsValid(url) {
      return !url || UrlUtil.isUrlValid(url);
    },

    dataIsValid() {
      const nameIsValid = this.productIsValid( this.editedItem?.name?.trim() )
      const urlIsValid = this.urlIsValid(this.editedItem?.url) ? true : '';
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }

      if ( urlIsValid !== true ) {
        isNotValid.push( urlIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    productIsValid( v ) {
      return ( v && v.length > 0 ) || this.productsSnippet.required
    },

    deleteItem( item ) {
      let list = this.searchField ? this.paginatedSearchedProductsList : this.paginatedProductsList;
      this.editedIndex = list.findIndex( product => product.id === item.id );
      this.editedItem = this.getObjClone( list[this.editedIndex] );
      this.dialogDelete = true
    },


    deleteItemConfirm() {
      let paginatedList = this.searchField ? this.paginatedSearchedProductsList : this.paginatedProductsList;
      let list = this.searchField ? this.searchedProductsList : this.productsList;
      paginatedList.splice( this.editedIndex, 1 )
      list.splice( this.listEditedIndex, 1 )

      ApiRequestService.deleteRequest( `api/admin-products/${ this.editedItem.id }` ).then()

      this.closeDelete()
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) )
    },

    close() {
      this.dialog = false
      this.submitted = false
      this.errorKey = ''

      /**
       * if action is edit, set to form old data
       */
      let data
      if ( this.editedIndex !== -1 ) {
        data = this.getObjClone( this.oldProduct )
      } else {
        data = this.getObjClone( this.defaultItem )
      }

      for ( let [ key, value ] of Object.entries( data ) ) {
        if ( value && typeof value === 'object' ) {
          for ( let [ valueKey, valueValue ] of Object.entries( value ) ) {
            this.$set( value, valueKey, valueValue )
          }
        } else {
          this.$set( this.editedItem, key, value )
        }
      }

      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    closeDelete() {
      this.dialogDelete = false
      this.submitted = false
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    async saveProduct() {
      let isValid = this.dataIsValid()
      if ( isValid !== true ) {
        return
      }

      let dataToCreate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( value ) {
          dataToCreate[key] = value;
        }
      }
      const productData = await ApiRequestService.postRequest( 'api/admin-products', dataToCreate );

      if ( productData?.errorMessage ) {
        this.errorKey = productData?.errorMessage?.split( ',' );
        return;
      }
      if ( !productData ) {
        return;
      }

      if ( productData.id ) {
        this.$router.push( `product-details/${ productData.id }` )
      }

    },

    save() {
      this.submitted = true

      this.saveProduct()
    },


  },
}

