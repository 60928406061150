export default class UrlUtil {
  static isUrlValid(url) {
    if (typeof url !== 'string') {
      return false;
    }

    if (url !== url.trim()) {
      return false;
    }

    const oldUrl = url;

    try {
      url = new URL(url);

      if (!['http:', 'https:'].includes(url.protocol)) {
        return false;
      }

      if (!url.hostname.match(/^[\w-]+(\.[\w-]+)+$/gm)) {
        return false;
      }

      if (oldUrl !== url.href && oldUrl + '/' !== url.href) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  }
}
